@charset "UTF-8";

/*
* Author: Vasterad
* URL: https://themeforest.net/user/vasterad/portfolio/
*/

@import 'variables';

/* -------------------------------------------------------------- */
/* 01. Layout Components
----------------------------------------------------------------- */
@import 'layout/bootstrap-grid';
@import 'layout/form-styles';
@import 'layout/header';
@import 'layout/header-notifications';
@import 'layout/user-menu';
@import 'layout/navigation';
@import 'layout/mobile-navigation';
@import 'layout/titlebar';
@import 'layout/blog';
@import 'layout/contact-page';
@import 'layout/footer';
@import 'layout/sidebar-styles';
@import 'layout/single-freelancer-page';
@import 'layout/single-job-page';
@import 'layout/checkout-page';
@import 'layout/404-page';


/* -------------------------------------------------------------- */
/* 02. Listings Styles
----------------------------------------------------------------- */
@import 'listings/full-page-layout';
@import 'listings/jobs-list-layout';
@import 'listings/jobs-compact-list-layout';
@import 'listings/jobs-grid-layout';
@import 'listings/tasks-list-layout';
@import 'listings/tasks-grid-layout';
@import 'listings/freelancers-grid-layout';
@import 'listings/freelancers-list-layout';
@import 'listings/companies-list';


/* -------------------------------------------------------------- */
/* 03. Graphic User Interface Elements
----------------------------------------------------------------- */
@import 'gui/buttons';
@import 'gui/lists';
@import 'gui/marks';
@import 'gui/notification-boxes';
@import 'gui/tables';
@import 'gui/accordion';
@import 'gui/tabs';
@import 'gui/copy-to-clipboard';
@import 'gui/share-buttons';
@import 'gui/verified-badge';
@import 'gui/star-rating';
@import 'gui/leave-rating-stars';
@import 'gui/ripple-effect';
@import 'gui/on-off-switch';
@import 'gui/radio-button';
@import 'gui/checkbox';
@import 'gui/custom-upload-button';
@import 'gui/intro-banner';
@import 'gui/category-boxes';
@import 'gui/photo-box';
@import 'gui/pricing-plans';
@import 'gui/icon-boxes';
@import 'gui/testimonials';
@import 'gui/photo-section';
@import 'gui/counters';
@import 'gui/countdown';
@import 'gui/bidding-widget';
@import 'gui/boxed-list';
@import 'gui/category-boxes';
@import 'gui/clickable-tags';
@import 'gui/icon-boxes';
@import 'gui/notify-box';
@import 'gui/pagination';
@import 'gui/back-to-top';


/* -------------------------------------------------------------- */
/* 04. Vendor (third party assets styles)
----------------------------------------------------------------- */
@import 'vendor/slick-carousel';
@import 'vendor/custom-scrollbar';
@import 'vendor/tooltips';
@import 'vendor/popups';
@import 'vendor/bootstrap-range-slider';
@import 'vendor/bootstrap-select';
@import 'vendor/snackbar';
@import 'vendor/google-autocomplete';
@import 'vendor/maps';
@import 'vendor/leaflet-maps';


/* -------------------------------------------------------------- */
/* 05. Dashboard
----------------------------------------------------------------- */
@import 'dashboard/dashboard-basics';
@import 'dashboard/dashboard-navigation';
@import 'dashboard/dashboard-gui-elements';
@import 'dashboard/dashboard-messages';


/* -------------------------------------------------------------- */
/* 06. Layout Basics
----------------------------------------------------------------- */
@import 'layout/common-typography';
@import 'layout/offsets';


/* -------------------------------------------------------------- */
/* 07. Icon Sets
----------------------------------------------------------------- */
@import 'icons/material-icons';
@import 'icons/feather-icons';
@import 'icons/line-awesome-icons';
@import 'icons/brand-icons';


/* -------------------------------------------------------------- */
/* 08. Custom CSS
----------------------------------------------------------------- */
@import 'custom';

